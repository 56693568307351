
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Partials/Header';
import Footer from '../Partials/Footer';
import pdf1 from '../Assets/diverse/datenschutzerklaerung.pdf';

function ImpressumPage() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <>
 <Header />

 <main >
    
<section className="my-page-container">


  <div className="columns"> 
  <div className="column is-one-quarter ">

  <aside className="menu is-hidden ">
  <p className="menu-label">
    Menu
  </p>
  <ul className="menu-list">
    <li>  <a href="#imp">Impressum</a></li>
    <li>  <a href={pdf1}>Datenschutzerklärung</a></li>
  </ul>
 
</aside>




  </div>
  <div className="column xis-offset-one-quarter">
    <h1 id="imp">Impressum</h1>

  <div className="columns"> 
  <div className="column">
      <h4>Herausgeber</h4>
    <p>Schulverwaltung Volketswil<br/>
Zentralstrasse 21<br/>
8604 Volketswil</p>

      <h4 className="mt-5">Copyright</h4>
    <p>Schulverwaltung Volketswil<br/>
Zentralstrasse 21<br/>
8604 Volketswil</p>
  </div>
  <div className="column">
  <h4>Webdesign & Entwicklung:</h4>
  <a href="https://www.typobyte.ch/" target="_blank">TYPOBYTE Webdesign</a><br/> 
  Jonastrasse 7<br/> 
  8636 Wald ZH
  </div>
  </div>

<div className="mt-6 is-hidden">



  <a href={pdf1}><h2>Datenschutzerklärung</h2></a>




</div> 


  </div>
  </div> 

  </section>


  </main>
  <Footer />
    </>
  );
}

export default ImpressumPage;
