import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import pdf1 from '../Assets/diverse/datenschutzerklaerung.pdf';
function Footer() {
  return (
    
<footer className="footer">
<ScrollToTop smooth />
  <div className="container has-text-centered">
<div id="copyright">               
          
                <i className="fa fa-copyright"></i> 2023 Schulen Volketswil <br/> 
                <NavLink to="/Impressum" className="is-inline">Impressum</NavLink>&nbsp;|&nbsp;
                <a href={pdf1} className="is-inline">Datenschutzerklärung</a><br/><br/>
                <a href="http://www.typobyte.ch" target="_blank">TYPOBYTE Webdesign with <i className="fa fa-heart"></i></a>
            </div>
  </div>
</footer>


  );
}

export default Footer;
