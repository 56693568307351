//const API_BASE_URL = 'http://html.loc/sogehtschule/api.php';
//const API_BASE_URL = 'https://sogehtschule.ch/api.php';
const API_BASE_URL = '/api.php';

export const API_ENDPOINTS = {
  FUNCTIONS: `${API_BASE_URL}?url=Funktionen`,
  JOBS: `${API_BASE_URL}?url=Jobs`,
  STUFEN: `${API_BASE_URL}?url=Stufen`,
  POST: `${API_BASE_URL}`,
  // Add more endpoints here
};
