const Lightbox = ({ isActive, onClose, onComplete, isLoading }) => (
  <div className={`my-modal-wrapper${isActive ? '' : ' is-hidden'}`}>
    <div className={` modal${isActive ? ' is-active' : ''}`}>
    <div className="modal-background" />
      <div className="modal-card m-4">
    <header className="modal-card-head">
      <h3 className="modal-card-title">Vielen Dank für deine Anfrage</h3>

      <button className="delete" onClick={onClose} aria-label="close"></button>
    </header>

    <section className="modal-card-body">

  <p> Wir haben deine Angaben erhalten und werden uns innerhalb von 24 Stunden bei dir melden, um weitere Optionen zu besprechen.</p>
<br/>
  <p>Unser Team ist bemüht, dir bei der Suche nach deiner Wunschstelle zu helfen und wir freuen uns darauf, mit dir in Kontakt zu treten!</p>
 
    </section>
    <footer className="modal-card-foot is-flex is-justify-content-flex-end">
      <div className=" mb-4 mr-4">
        <button className="is-success" aria-label="close" onClick={onClose}>
 OK <i className="fa-sharp fa-solid fa-thumbs-up"></i>  </button>

      </div>
    </footer>

    </div></div>
    </div>
);

export default Lightbox;