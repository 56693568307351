import React, {useRef,useEffect} from 'react';
import {NavLink } from 'react-router-dom';
import NavbarBurger from './NavbarBurger';

function Header() {
  const myComponentRef = useRef(null);


  const scrollToComponent = () => {
    window.scrollTo({
      top: myComponentRef.current.offsetTop,
      behavior: "smooth",
      duration: 50
    });
  };
   
  useEffect(() => {
    scrollToComponent();

  }, []);


  return (
<header ref={myComponentRef} >
    <nav className="navbar container" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <a className="navbar-item" href="/">
                <img className="mt-4 mb-4" src="https://www.schule-volketswil.ch/fileadmin/templates/img/logo.png" alt="" width="200px" />
            </a>
            <NavbarBurger className="navbar-end" target="navbarMenu" />
        </div>

        <div id="navbarMenu" className="navbar-menu">

            <div className="navbar-end">

                <NavLink className="navbar-item" to="/contact">
                    KONTAKT
                </NavLink>
            </div>
        </div>
    </nav>
</header>
  );
}

export default Header;
