import React, { useState, useEffect } from 'react';
import JobFilter from '../Jobs/JobFilter';
import JobResults from '../Jobs/JobResults';
import Header from '../Partials/Header';
import Footer from '../Partials/Footer';
import Benefits from '../Partials/Benefits';
import SchulenBild from '../Assets/fotos/schulen.png';

function HomePage() {
  const [filters, setFilters] = useState({ adl: false, function: '', faecher: [] });
  const [toggleResults, setToggleResults] = useState(true);

  const handleVisibility = () => {
    setToggleResults(!toggleResults);
  };

  return (
<>
    <Header />
    <main >
        {toggleResults ? (
        <>
            <section className="hero is-medium is-primary has-background">
                <img className="hero-background is-transparent" src={SchulenBild} />
                <div className="hero-body">
                    <div className="container">
                        <h1 className=" has-text-centered">
                            Wetten, dass es dir bei uns gefällt?
                        </h1>
                    </div>
                </div>
            </section>
            <section className="App">
                <JobFilter filters={filters} setFilters={setFilters} onFilterSubmit={handleVisibility} />
            </section>
                <Benefits />
        </>
        ):(
            <JobResults filters={filters} onBackClick={handleVisibility}  />
        )}
    </main>
    <Footer />
</>
  );
}

export default HomePage;
