import React from 'react';
import img1 from '../Assets/testimonials/oliver.png';
import img2 from '../Assets/testimonials/christine.png';
import img3 from '../Assets/testimonials/marianne.png';
import img4 from '../Assets/testimonials/marco.png';


function Benefits() {
  return (
    

<section className="section container">
<div className="columns iconlist">

     <div className="column "><div className="iconfact has-background-warning ">
     

       <div className="quant"><span> 260 </span></div>
       <i className="fa-solid fa-person-chalkboard"></i>
       <div className="txt">Engagierte<br/> Lehrpersonen</div>
     </div>
     </div>
     <div className="column"><div className="iconfact   bgColGrad3">
     
       <div className="quant"><span> 2'300 </span></div>
     <i className="fa-solid fa-children"></i>  
       <div className="txt">Kinder & <br/> Jugendliche</div>
     </div>
     </div>
     <div className="column"><div className="iconfact   bgColGrad2">
     
       <div className="quant"><span> 14</span></div>
       <i className="fa-solid fa-school"></i>
       <div className="txt">Schulhäuser & <br/> Kindergärten</div>
     </div>
     </div>
     <div className="column"><div className="iconfact bgColGrad1  ">
     
       <div className="quant"><span> ∞</span></div>
       <i className="fa-sharp fa-solid fa-square-parking"></i>
       <div className="txt">Kostenlose <br/> Parkplätze</div>
     </div>
     </div>
</div>



<div className="columns is-desktop mt-6 testimonial ">
<div className="column  is-one-half">
<figure class="fig1 ">
  <figcaption>
    <blockquote>  Im Feldhof darf man über den Tellerrand blicken, kann viel unternehmen und sich mit seinem ganzen Sein einbringen, wird unterstützt, geachtet und gesehen.</blockquote>
  <span className="autor">Oliver, Klassenlehrperson</span>
  </figcaption>
 <div className="imgWrap "> 
 <div class="arrow"></div>
 <img src={img1} alt="sq-sample10"/>
 </div>

</figure></div>

<div className="column  is-one-half">
<figure class="fig2 ">
  <figcaption>
    <blockquote> Ich habe mit der Schule Hellwies einen Ort gefunden, an dem ich meine Stärken einbringen kann. Im Team erfahre ich viel Wertschätzung und die stufenübergreifende Zusammenarbeit sehe ich als grosse Bereicherung.
</blockquote>
     <span className="autor">Christine, Klassenlehrperson</span>
  </figcaption>
 <div className="imgWrap "> 
 <div class="arrow"></div>
 <img src={img2} alt="sq-sample10"/>
 </div>

</figure>
</div>

  </div>

  <div className="columns is-desktop testimonial ">
<div className="column  is-one-half">
<figure class="fig3 ">
  <figcaption>
    <blockquote>Ich arbeite gerne im Schulhaus Hellwies, weil es mir gefällt, in einem motivierten und innovativen Team zu arbeiten, in dem engagierte, konstruktive Unterstützung gross geschrieben wird.
</blockquote>

     <span className="autor">Marianne, Klassenlehrperson</span>
  </figcaption>
 <div className="imgWrap "> 
 <div class="arrow"></div>
 <img src={img3} alt="sq-sample10"/>
 </div>

</figure></div>

<div className="column  is-one-half">
<figure class="fig4 ">
  <figcaption>
    <blockquote>Um Teil des tollen PBS-Teams zu sein, nehme ich gerne täglich einen Arbeitsweg von 230 km in Kauf.
</blockquote>
  <span className="autor">Marco, Schulsozialarbeit</span>   

  </figcaption>
 <div className="imgWrap "> 
 <div class="arrow"></div>
 <img src={img4} alt="sq-sample10"/>
 </div>

</figure>
</div>

  </div>





  </section>

  );
}

export default Benefits;
