import { useState } from 'react';

function NavbarBurger({ target }) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    const targetElement = document.getElementById(target);
    targetElement.classList.toggle('is-active');
  };

  return (
    <a
      className={`navbar-burger ${isActive ? 'is-active' : ''}`}
      onClick={handleClick}
      role="button" 
      aria-label="menu"
      aria-expanded="false"
      data-target={target}
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  );
}

export default NavbarBurger;
