import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import logo from './logo.svg';
import './Sass/App.scss';
import React, { useState, useEffect } from 'react';


import HomePage from './Pages/HomePage';
import ContactPage from './Pages/ContactPage';
import ImpressumPage from './Pages/ImpressumPage';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}



function App() {


    return (
       <ErrorBoundary>
 <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="contact/*" element={<ContactPage />} />
        <Route path="impressum/*" element={<ImpressumPage />} />
      </Routes>
    </BrowserRouter>
</ErrorBoundary>

    );
}

export default App;