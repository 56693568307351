import React, { useState, useEffect,useMemo } from 'react';
import PropTypes from "prop-types";
import { API_ENDPOINTS } from '../Tools/apiConfig';


function StufenSelect(props) {
  const [selectedStufe, setSelectedStufe] = useState(null);
  // const [stufen, setStufen] = useState([]);
  const [fächer, setFächer] = useState([]);
  const [selectedFacher, setSelectedFacher] = useState([]);


  const [stufen, setStufen] = useState([{
    fields: {
      Name: 'lade, bitte warten...'
    },
    id: '000'
    }]);

  useMemo( async () => {
        try {
          const response = await fetch(API_ENDPOINTS.STUFEN);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();

          const records = data.records;
          records.map((record, ind) => {
          let FächerMerged = [];
           if (records[ind].fields.Fächer) {
             records[ind].fields.Fächer.forEach(
              (element, index) => {
                FächerMerged.push({'id': element, 'name': records[ind].fields.fName[index]});
              });
             records[ind].fields.Fächer = FächerMerged;
            }

          })

          let emptyOption = {
            fields: {
              Name: '-- Stufe wählen -- '
            },
            id: '000'
          };

          records.unshift(emptyOption);

          setStufen(records);
        
        } catch (error) {
          console.error('Error fetching function data:', error);
        }
      }
    , []);

  useEffect(() => {
    if (selectedStufe) {
      setFächer(selectedStufe.fields["Fächer"] || []);
    } else {
      setFächer([]);
    }
  }, [selectedStufe]);


const handleChangeStufe = (event) => {
    const stufeId = event.target.value;
    const stufe = stufen.find((s) => s.id === stufeId);
    setSelectedStufe(stufe);
    props.setFilters({ ...props.filters, stufe: stufe ? stufe.fields.Name : null, faecher: [] });
    setSelectedFacher([]);
  };



function handleCheckboxChange(e) {
    const value = e.target.value;

    if (selectedFacher.includes(value)) {
      setSelectedFacher(selectedFacher.filter((fach) => fach !== value));
      props.setFilters({ ...props.filters, faecher: props.filters.faecher.filter((fach) => fach !== value)})
      // props.filters.faecher = props.filters.faecher.filter((fach) => fach !== value);
    } else {
      setSelectedFacher([...selectedFacher, value]); 
      props.setFilters({ ...props.filters, faecher: [...selectedFacher, value]})

    }
    
  }


function handleRadioChange(e) {
  const value = e.target.value;
  setSelectedFacher(value);
  props.setFilters({ ...props.filters, faecher: [value] });
}



 return (
    <>
    <div className="field">
            <div className="control select">        
      <select
        id="stufe-select"
        value={selectedStufe ? selectedStufe.id : ""}
        onChange={handleChangeStufe}
      >
        {stufen.map((stufe) => (
          <option key={stufe.id} value={stufe.id}>
            {stufe.fields["Name"]}
          </option>
        ))}
      </select>
      </div>
</div>
{selectedStufe && (
  <>
    {fächer.length === 0 ? (
      ''
    ) : (
      <div className="faecher">
      <h3 className="is-size-4 text-center">Mein Wunschfach (optional):</h3>
      <div className="checkboxes pt-3 mb-3">
        {fächer.map((fach) => (
                <div key={fach.id} className="field tagbox">
                  <input
                    className="is-checkradio is-block is-warning"
                    type="radio"
                    id={`fach-${fach.id}`}
                    name="facher"
                    value={fach.name}
                    checked={selectedFacher.includes(fach.name)}
                    onChange={handleRadioChange}
                  />
                  <label className="radio" htmlFor={`fach-${fach.id}`}> {fach.name}</label>
                </div>
        ))}
      </div>
      </div>
    )}
  </>
)}


    </>
  );
}

StufenSelect.propTypes = {
  onChange: PropTypes.func,
};

export default StufenSelect;