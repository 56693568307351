import React, { useState,useEffect } from 'react';
import JobResults from './JobResults';
import FunctionSelect from './FunctionSelect';
import StufenSelect from './StufenSelect';
import BgSlider from './BgSlider';

function JobFilter(props) {
  const [adlChecked, setAdlChecked] = useState(false);
  const [showStufe, setShowStufe] = useState(false);
  const [percentage, setPercentage] = useState(100);
  const [functionError, setFunctionError] = useState(false);
  const ohneFaecherList = ['Logopädin','Schulische Heilpädagogin']; // Add more i u like

  const handleButtonClick = () => {
    if (functionError) {
      return
    }
    props.onFilterSubmit();
  };


  useEffect(() => {
    (!props.filters.function) ? // Function Required
      setFunctionError(true):
      setFunctionError(false);

    let ohneFaecher = ohneFaecherList.includes(props.filters.function);
    props.filters.stufe = '';
    setShowStufe(!ohneFaecher)
  }, [props.filters.function]);   // Check if props.filter has changed


  // useEffect(() => {
  //   props.setFilters({ ...props.filters, percentage: 100 });
  // }, []);

  return (
    <>


    <section className="jobfiter is-hiddenx">
      <form>
      <h2 className="mb-5 has-text-centered">Konfiguriere deine Wunschstelle!</h2>
        {/*<div className="error mb-3">Funktion wählen</div>*/}
        <FunctionSelect filters={props.filters} setFilters={props.setFilters}/>

        {showStufe && <StufenSelect filters={props.filters} setFilters={props.setFilters} />}
        


        <div className="control">
          <div className="field is-hidden ">
            <input
              className="is-checkradio is-block is-warning" 
              type="checkbox"
              id="adl-checkbox"
              checked={props.filters.adl}
              onChange={(e) => {
                props.setFilters({ ...props.filters, adl: !adlChecked });
                setAdlChecked(!adlChecked);
              }}
            /> 
            <label className="checkbox" htmlFor="adl-checkbox">AdL</label>
          </div>
        </div>

        
        <div className="control">
          <br />
          <button className="is-fullwidth" type="button" onClick={handleButtonClick} disabled={functionError}> 
           <i className="fas fa-search"></i> SUCHEN
          </button>
        </div>
      </form>
      </section>

    </>
  );
}

export default JobFilter;
