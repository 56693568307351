import React, { useState, useEffect, useMemo } from 'react';
import { API_ENDPOINTS } from '../Tools/apiConfig';

function FunctionSelect(props) {
  const [functions, setFunctions] = useState([{
    fields: {
      Name: 'lade, bitte warten...'
    },
    id: '000'
  }
  ]);


  // useEffect(() => {
const getFunctions = useMemo( async () => {
    // Promise
    // async function fetchData() {
      try {
        const response = await fetch(API_ENDPOINTS.FUNCTIONS);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

                //<option value="">-- Funktion wählen -- </option>
                //
        let emptyOption = {
          fields: {
            Name: '-- Funktion wählen -- '
          },
          id: '000'
        };

        data.records.unshift(emptyOption);

        setFunctions(data.records);
      } catch (error) {
        console.error('Error fetching function data:', error);
        alert('Netzwerkverbindung fehlerhaft. Versuchen Sie die Seite neu zu laden')
      } finally {
      //    setIsLoading(false); // hide the loading indicator after the API call is completed
      }
    }
    // Function Call
    // fetchData();
  // }, []);

  , []);




  return (
<>

       <div className="field">
          <div className="control select">


      <select
        id="function-select"
        name="function"
        value={props.filters.function}
        onChange={(event) => {
          props.setFilters({ ...props.filters, function: event.target.value })}
        } 
      >
        {functions.map((funct) => (
          <option key={funct.id} value={funct.fields.Name}>
            {funct.fields.Name}
          </option>
        ))}
      </select>
      
          </div>
        </div>

</>
  );
}

export default FunctionSelect;
