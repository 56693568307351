
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Partials/Header';
import Footer from '../Partials/Footer';

function ContactPage() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <>
 <Header />







 <main >
    
<section className="my-page-container">

  <div className="columns"> 
  <div className="column is-one-quarter">
  <aside className="menu">


 
</aside>



  </div>
  <div className="column xis-offset-one-quarter">
    <h1>KONTAKT</h1>


      <h4>Schulverwaltung Volketswil</h4>
<p>Zentralstrasse 21<br/>
8604 Volketswil</p>

<div className="mt-6">

      <h3>Wohin mit Ihrem Anliegen?</h3>
      <p>Die Schulverwaltung hilft Ihnen gerne die richtige Ansprechperson zu finden:</p>
<br/>
<p>
<a href="tel:0449102222">044 910 22 22</a>
  </p>
  <p>
<a href="mailto:schule@volketswil.ch">schule@volketswil.ch</a>
  </p>

</div> 


  </div>
  </div> 

  </section>


  </main>
  <Footer />
    </>
  );
}

export default ContactPage;
