const Lightbox = ({ isActive, onClose, onComplete, isLoading }) => (
  <div className={`my-modal-wrapper${isActive ? '' : ' is-hidden'}`}>
    <div className={` modal${isActive ? ' is-active' : ''}`}>
    <div className="modal-background" />
      <div className="modal-card m-4">
    <header className="modal-card-head">
      <h2 className="modal-card-title">Fast geschafft!</h2>
      <button className="delete" onClick={onClose} aria-label="close"></button>
    </header>

    <section className="modal-card-body">
        
      <p>
Eine Schulleitung der Schule Volketswil wird innerhalb von 24 Stunden mit dir Kontakt aufnehmen.
      </p>
    </section>
    <footer className="modal-card-foot is-flex is-justify-content-flex-end">
      <div className=" mb-4 mr-4">
        <button className={`is-fullwidth is-success${isLoading ? ' is-loading' : ''}` } aria-label="close" onClick={onComplete}><i className="fas fa-paper-plane"></i> 
 Ok, senden!</button>

      </div>
    </footer>

    </div></div>
    </div>
);

export default Lightbox;