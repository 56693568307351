import React, { useState, useEffect, useRef } from 'react';
import Lightbox from '../Tools/Lightbox';
import ReCAPTCHA from 'react-google-recaptcha';
import { API_ENDPOINTS } from '../Tools/apiConfig';


function ContactForm(props, onClose) {
  const [errors, setErrors] = useState({});
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const recaptchaRef = React.createRef();
  const myComponentRef = useRef(null);


  const scrollToComponent = () => {
    window.scrollTo({
      top: myComponentRef.current.offsetTop,
      behavior: "smooth",
      duration: 50
    });
  };
   
  const [formValues, setFormValues] = useState({
    anrede: '',
    vorname: '',
    name: '',
    email: '',
    telefon: '',
    kontakt: '',
    bgmin: '',
    bgmax: '',
    kontakt: '',
    bemerkungen: '',
    captcha: '',
  });

 const [formErrors, setFormErrors] = useState({
    anrede: '',
    vorname: '',
    name: '',
    email: '',
    telefon: '',
    kontakt: '',
    captcha: '',
  });

    const [showLightbox, setShowLightbox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

  

    const closeLightbox = () => {
      setShowLightbox(false);
    };


  const hiddenFields = (
    <>
    <h3>Debug Only</h3>
      <input type="text" name="function" defaultValue={props.filter.filters.function} />
      <input type="text" name="stufe" defaultValue={props.filter.filters.stufe} />
      <input type="text" name="percentage" defaultValue={props.filter.filters.percentage} />
      <input type="text" name="adl" defaultValue={props.filter.filters.adl} />
      <input type="text" name="faecher" defaultValue={props.filter.filters.faecher.join(';')} />
      <br />Gefundene Schulhäuser: {Object.keys(props.jobs).join(',')}<br /><br />
    </>
  );


  const validateForm = (formValues) => {
    // Validate form fields
    const errors = {};
    if (!formValues.anrede) {
      errors.anrede = 'Bitte wählen';
    } else {
      delete formErrors.anrede;
    }
    if (!formValues.kontakt) {
      errors.kontakt = 'Bitte wählen';
    } else {
      delete formErrors.kontakt;
    }

    if (!formValues.name) {
      errors.name = 'Bitte gib einen Namen ein';
    } else {
      delete formErrors.name;
    }
    if (!formValues.vorname) {
      errors.vorname = 'Bitte gib einen Vornamen ein';
    } else {
      delete formErrors.vorname;
    }
    if (!formValues.email) {
      errors.email = 'Bitte gib eine E-Mail-Adresse ein';
    } else if (!isValidEmail(formValues.email)) {
      errors.email = 'Bitte gib eine gültige E-Mail-Adresse ein';
    } else {
      delete formErrors.email;
    }
    if (!formValues.telefon) {
      errors.telefon = 'Bitte gib eine Telefonnummer an';
    } else {
      delete formErrors.telefon;
    }
    // if (!formValues.captcha) {
    //   errors.captcha = 'Bitte reCaptcha bestätigen';
    // } else {
    //   delete formErrors.captcha;
    // }

    //               <ReCAPTCHA
    //             ref={recaptchaRef}
    //             size="normal"
    //             sitekey="6LfttyElAAAAAMqGouUPIEZyvrLShDB8Y3nSkOhW"
    //             onChange={handleRecaptcha}
    //           />
    //           {isSubmitted && formErrors.captcha && <div className="error">{formErrors.captcha}</div>}


    setFormErrors(errors);
  }

  useEffect(() => {
    validateForm(formValues);
  }, [formValues]);

  useEffect(() => {
    scrollToComponent();
  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };



  function flattenJobs(jobs) {
    const jobIds = [];

    for (let schulhausId in jobs) {
      const schulhaus = jobs[schulhausId];
      for (let jobId in schulhaus) {
        jobIds.push(schulhaus[jobId].id);
      }
    }

    return jobIds;
  }

  const submitForm = () => {
        
        const data = {
            "records": [
              {
                "fields": {
                  "Anrede": formValues.anrede,
                  "Vorname": formValues.vorname,
                  "Name": formValues.name,
                  "Email": formValues.email,
                  "Telefon": formValues.telefon,
                  "Kontakt per": formValues.kontakt,
                  "Bemerkungen": formValues.bemerkungen,
                  "BG max": formValues.bgmax,
                  "BG min": formValues.bgmin,
                  "Function": props.filter.filters.function,
                  "Stufe": props.filter.filters.stufe,
                  // "Percentage": props.filter.filters.percentage,
                  "ADL": props.filter.filters.adl,
                  "Fächer": props.filter.filters.faecher.join(';'),
                  "Schulhaus": Object.keys(props.jobs).join(';'),
                  "Jobs": flattenJobs(props.jobs).join(';'),
                }
              }
            ]
          };

          setIsLoading(true); // set loading status to true
          
          closeLightbox();
            props.onClose({submitted: true});

          fetch(API_ENDPOINTS.POST, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          .then(response => response.json())
          .then(data => {
            setIsLoading(false); // set loading status to false
            closeLightbox();
            props.onClose({submitted: true});
            // Handle response data
          })
          .catch(error => {
            props.onClose({submitted: false});
            console.log( error);
            alert('Verbindungsfehler. Die Nachricht konnte nicht gesendet werden!')

          });

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    validateForm(formValues);
    setRecaptchaResponse(null); // reset recaptchaResponse state variable
    if (Object.keys(formErrors).length === 0) {

       setIsLoading(false);
       setShowLightbox(true);
     
    }
  };


const handleRecaptcha = () => {
  const recaptchaValue = recaptchaRef.current.getValue();
  formValues.captcha = recaptchaValue;
  if (recaptchaValue) {
    setFormErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors.captcha;
      return newErrors;
    });

    handleSubmit(recaptchaValue);
  }
  else {
    console.error("reCAPTCHA value is null");
  }
};


  const isValidEmail = (email) => {
    // A simple email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  




  return (
    
    
 <>
<section  ref={myComponentRef} className="my-page-container">

 <Lightbox isActive={showLightbox}  onClose={closeLightbox} onComplete={submitForm} isLoading={isLoading} />
 <section className="message is-primary form-width container mt-vh">
  <div className="message-header">
  <h3>Hier geht's zu deinem Traumjob!</h3>
     <button className="delete is-large" onClick={props.onClose} />
  </div>
    <div className="message-body pt-5">

    <div className="mb-6">
      Gerne helfen wir dabei deine Wunschstelle zu finden!<br /> <br /> Unser Team wird sich innerhalb von 24 Stunden bei dir melden und wir können dann gerne in einem persönlichen Gespräch weitere Optionen besprechen. 

    </div>

    <form onSubmit={handleSubmit}>
    <div className="columns">
<div className="column">
      <div className="field ">
      <div className="control select">
        <select name="anrede" value={formValues.anrede} onChange={handleChange} >
          <option value="">-- Anrede --</option>
          <option value="Frau">Frau</option>
          <option value="Herr">Herr</option>
        </select>
       </div>
      {isSubmitted && formErrors.anrede && <div className="error">{formErrors.anrede}</div>}
      </div>
</div>

<div className="column">
      <div className="field">
      <div className="control select">
       
        <select name="kontakt" value={formValues.kontakt} onChange={handleChange} >
          <option value="">--  Kontakt per: --</option>
          <option value="Telefon">Telefon</option>
          <option value="Video Call">Video Call</option>
          <option value="Beides">Beides</option>
        </select>
      </div >
      {isSubmitted && formErrors.kontakt && <div className="error">{formErrors.kontakt}</div>}
      </div>

  </div>
  </div>
<div className="columns">

<div className="column">
      <div className="field">
       <div className="control">
        <input placeholder="Vorname" type="text" name="vorname" value={formValues.vorname} onChange={handleChange}  />
      </div>
        {isSubmitted && formErrors.vorname && <div className="error">{formErrors.vorname}</div>}
      </div>


</div>
      
<div className="column">

      <div className="field">
     <div className="control">
        <input placeholder="Name" type="text" name="name" value={formValues.name} onChange={handleChange}  />
      </div>
      {isSubmitted && formErrors.name && <div className="error">{formErrors.name}</div>}
      </div>

</div>

</div>

<div className="columns">

<div className="column">
      <div className="field">
       <div className="control">
            <input placeholder="Beschäftigungsgrad min. (%)" type="text" name="bgmin" value={formValues.bgmin}  onChange={handleChange}  />
      </div>
      </div>
      
  </div>

<div className="column">
      <div className="field">
       <div className="control">
            <input placeholder="Beschäftigungsgrad max. (%)" type="text" name="bgmax" value={formValues.bgmax}  onChange={handleChange}  />
      </div>
      </div>
      
  </div>
</div>
  


<div className="columns">

<div className="column">
      <div className="field">
       <div className="control">
            <input placeholder="E-Mail" type="email" name="email" value={formValues.email} onChange={handleChange}  />
      </div>
      {isSubmitted && formErrors.email && <div className="error">{formErrors.email}</div>}
      </div>
      
  </div>

  <div className="column">

      <div className="field">
       <div className="control">
            <input placeholder="Telefon" type="tel" name="telefon" value={formValues.telefon} onChange={handleChange}  />
      </div>
      {isSubmitted && formErrors.telefon && <div className="error">{formErrors.telefon}</div>}
      </div>
      
  </div>
</div>







      <div className="field">
      <div className="control">

        <textarea placeholder="Bemerkungen" name="bemerkungen" value={formValues.bemerkungen}  onChange={handleChange}> </textarea>
        </div>
        </div>

   <button type="submit" className="mt-6 mb-3 is-fullwidth button is-success">SENDEN</button>

      
    </form>
  </div>
  </section>
  </section>
    </>
    
  );
}

export default ContactForm;
