import React, { useEffect, useState, useMemo, useRef } from 'react';
import ContactForm from '../Jobs/ContactForm';
import LightboxEnd from '../Tools/LightboxEnd';
import { API_ENDPOINTS } from '../Tools/apiConfig';


function RenderImage({ data }) {
  return (
    <img
      className="item"
      src={data.thumbnails.large.url}
      alt={data.thumbnails.filename}
    />
  );
}

function JobResults(props) {
  const [jobs, setJobs] = useState([]);
  const [showContactForm, setShowContactForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // add a state for loading indicator
  const [isAfterSubmit, setIsAfterSubmit] = useState(false); 
  const [showLightbox, setShowLightbox] = useState(false);

  const myComponentRef = useRef(null);


  const scrollToComponent = () => {
    window.scrollTo({
      top: myComponentRef.current.offsetTop,
      behavior: "smooth",
      duration: 500
    });
  };
   

  const unfilteredJobs = useMemo( async () => {
    try {
      setIsLoading(true); // show the loading indicator
      const jobsResponse = await fetch(API_ENDPOINTS.JOBS);

      if (!jobsResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const jobsData = await jobsResponse.json();
console.log( jobsData);
      return jobsData.records;
    } catch (error) {
      console.error('Error fetching jobs data:', error);
      return [];
    } finally {
      setIsLoading(false); // hide the loading indicator after the API call is completed
    }
  }, []);

  const cachedJobs = useMemo(async () => {
    try {
      const jobResults = await unfilteredJobs;

      if (!jobResults) {
        return [];
      }

      let filteredJobs = [...jobResults];

      const { adl, function: func, stufe, faecher, percentage } = props.filters;

      if (adl) {
        filteredJobs = filteredJobs.filter((job) => 'AdL' in job.fields);
      }

      if (func) {
        filteredJobs = filteredJobs.filter(
          (job) => func === '' || func === job.fields.pFunktion[0]
        );
      }

      if (stufe) {
        filteredJobs = filteredJobs.filter(
          (job) => stufe === '' || stufe === job.fields.pStufe[0]
        );
      }

      if (faecher.length) {
        filteredJobs = filteredJobs.filter((job) => {
          if (!job.fields.nFaecher) return false;

          const hasAllFaecher = faecher.every((faecherItem) =>
            job.fields.nFaecher.includes(faecherItem)
          );
          return hasAllFaecher;
        });
      }

      // if (percentage) {
      //   filteredJobs = filteredJobs.filter((job) => {
      //     const bgMin = parseInt(job.fields.BG_Min * 100);
      //     const bgMax = parseInt(job.fields.BG_Max * 100);

      //     return bgMin <= percentage && bgMax >= percentage;
      //   });
      // }

      const groupedJobs = filteredJobs.reduce((result, job) => {
        const schulhausId = job.fields.Schulhaus[0];
        if (!result[schulhausId]) {
          result[schulhausId] = [];
        }
        result[schulhausId].push(job);
        return result;
      }, {});

      return groupedJobs;

    } catch (error) {
      console.error('Error filtering jobs data:', error);
      return [];
    }
  }, [unfilteredJobs, props.filters]);

  useEffect(() => {
    scrollToComponent();
    if (cachedJobs instanceof Promise) {
      cachedJobs.then((jobsData) => setJobs(jobsData));
    } else {
      setJobs(cachedJobs);
    }
  }, [cachedJobs]);

  const handleContactRequest = () => {
    setShowContactForm(true);
  };

  const handleBackClick = () => {
    props.onBackClick();
  };

  const handleNext = (args) => {
    if (args.submitted) {
      setIsAfterSubmit(true);
      setShowLightbox(true);
    }
    setShowContactForm(false);
  };


  const handleClose = () => {
    setShowLightbox(false);
    scrollToComponent();
  };




    return (
       <>
       
       {showContactForm && <ContactForm filter={props} jobs={jobs} onClose={handleNext} />}

        {!showContactForm && (

      <section className="my-page-container" >
      <span className={`bulma-loader-mixin ${isLoading ? '' : 'is-hidden'}`}></span>
      
     <LightboxEnd isActive={showLightbox}  onClose={handleClose} />

   


        <div id="job_results" className={`resultlist container  ${isLoading ? 'is-hidden' : ''}`} >
        
             {!isAfterSubmit ? ('') : (
               <>
               <h2 className="">Vielleicht deine neue Arbeitsstelle?</h2>
               <p className="mb-6 ">Wir freuen uns auf dich!</p>
               </>
              )}

<div className={`resultinfo mb-6 ${isAfterSubmit ? "is-hidden" : ""}`} ref={myComponentRef} >
            {Object.values(jobs).length ? (
              <>
              <button className="button is-info is-pulled-right mt-3 ml-6 mb-3" onClick={()=> handleBackClick()} ><i className="fas fa-search"></i> neue Suche</button>
              {Object.values(jobs).length === 1 ? (
              <h3>Eine Institution hat passende Stellen für dich:</h3>  
                ):(
                <h3>Folgende {Object.values(jobs).length} Institutionen haben passende Stellen für dich:</h3>
                )}
              
              
              </>
              ):(

<article className="message is-primary form-width container mt-vh">
  <div className="message-header">
                  <h3>Keine passende Stelle für dich gefunden. </h3>
  </div>
  <div className="message-body pt-5">
  <strong> Aber das bedeutet nicht, dass es keine anderen Möglichkeiten gibt! </strong><p>Wir möchten dich dazu ermutigen, unser Anfrageformular auszufüllen, damit wir uns ein genaueres Bild von deinen Fähigkeiten und Interessen machen können. So können wir dir besser helfen, den perfekten Job zu finden.</p>
    <div className="buttons mt-6 is-flex is-justify-content-space-between">
      <button className="button is-success " onClick={() => handleContactRequest()}><i className="fas fa-paper-plane"></i> Anfrage</button>
      <button className="button is-info" onClick={()=> handleBackClick()}><i className="fas fa-search"></i> neue Suche</button>
    </div>
  </div>
</article>

              )}
            </div>

      {Object.keys(jobs).map((schulhausId) => (

        <section key={schulhausId} className="group item box" >

        {jobs[schulhausId][0] && (
        <>
            <div className="columns">

              <div className="column is-one-third-desktop">
              <div className="card-image bulma-overlay-mixin-parent" >
            
                {jobs[schulhausId][0].fields.nSchulhausbild && jobs[schulhausId][0].fields.nSchulhausbild.length > 0 ? (
                <RenderImage data={jobs[schulhausId][0].fields.nSchulhausbild[0]} />
                
                ) : (
                <p>Kein Bild</p>
                )}
        
             <div className={` ${!isAfterSubmit ? "card-content bulma-overlay-mixin" : ""} bulma-center-mixin-parent`}>

             <h2 className={`has-text-white has-text-centered bulma-center-mixin ${isAfterSubmit ? "is-hidden" : ""}`}>JOBS: {jobs[schulhausId].length}</h2>
                </div>  


              </div>


              {isAfterSubmit ? ('') : (
                <>

                <button className="button is-success is-fullwidth mt-4" onClick={() => handleContactRequest()}>Super, ich bin interessiert!</button>
                    {jobs[schulhausId].map((job) => (
                         <div key={job.id} className="box2 mt-4 ">
         
                            <div className="inline is-hidden">
                       
                                <p>{job.fields.pFunktion[0]}{job.fields.AdL && (", AdL")}</p>
                                <p>{job.fields.pStufe && "Stufe: " + job.fields.pStufe.join(', ')}</p>
                                <p>{job.fields.nFaecher && "Fächer: " + job.fields.nFaecher.join(', ')}</p>
                                <p>BG: {job.fields.BG_Min} - {job.fields.BG_Max}</p>                            
                            </div>
                       </div>

                     ))}
                            </> 
              )}

              </div>

              <div className="text column">

                           <h2>{jobs[schulhausId][0].fields.nSchulhausname[0]}</h2> 
                  
              <p className="subtitle">{jobs[schulhausId][0].fields.nSchulhaustyp.sort().join(' und ')}</p>

              <p>{jobs[schulhausId][0].fields.nSchulhausBeschreibung}</p>

        {jobs[schulhausId][0].fields.nSchulhausWebsite && (
               <p className="pt-3">
                  <a className="pt-3" href={jobs[schulhausId][0].fields.nSchulhausWebsite} target="_blank" rel="noreferrer"> <i className="fas pr-1 fa-external-link-alt"></i> {jobs[schulhausId][0].fields.nSchulhausname[0]}</a>
              </p>
        )}

              <br/>
                    {jobs[schulhausId].map((job) => (
                                                 <p key={job.id} >{job.fields["PDF Link"] ? (
                                    <a href={job.fields['PDF Link']} target="_blank" rel="noreferrer"><i className="fas pr-1 fa-external-link-alt"></i> Stellenangebot: {job.fields.Titel}</a>
                                    ) : ( '')} </p>
                        ))}
           

              </div>  


            </div>

        </>
        )}
        
        </section>

      ))}


    </div>
   
        </section>
)}

        </>
    );

}





export default JobResults;